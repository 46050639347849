import logo from './logo.svg';
import './App.css';

function App() {
  const url = 'https://oudmane.me'
  return (
    <div className="App">
      <header className="App-header">
        <div style={{display: 'flex', gap: '2rem', flexDirection: 'column'}}>
          <button onClick={() => {
            window.gtag("event", "Received Measurements", {
              event_category: "Measurements",
              event_label: "Received Measurements",
              transport_type: "beacon",
            })
            window.location.assign(url)
          }}>location.assign + beacon</button>
          <button onClick={() => {
            window.gtag("event", "Received Measurements", {
              event_category: "Measurements",
              event_label: "Received Measurements",
            })
            window.location.assign(url)
          }}>location.assign</button>
          <button onClick={() => {
            window.gtag("event", "Received Measurements", {
              event_category: "Measurements",
              event_label: "Received Measurements",
              transport_type: "beacon",
            })
            window.location.replace(url)
          }}>location.replace + beacon</button>
          <button onClick={() => {
            window.gtag("event", "Received Measurements", {
              event_category: "Measurements",
              event_label: "Received Measurements",
            })
            window.location.replace(url)
          }}>location.replace</button>
          <button onClick={() => {
            window.gtag("event", "Received Measurements", {
              event_category: "Measurements",
              event_label: "Received Measurements",
              transport_type: "beacon",
            })
            window.location.href = url
          }}>location.href + beacon</button>
          <button onClick={() => {
            window.gtag("event", "Received Measurements", {
              event_category: "Measurements",
              event_label: "Received Measurements",
            })
            window.location.href = url
          }}>location.href</button>
        </div>
      </header>
    </div>
  );
}

export default App;
